import { createApp } from 'vue'
import App from './App.vue'
import hm from './components/HelloWorld.vue'
import { createRouter, createWebHistory } from "vue-router";


import Pay from './components/pay.vue'
import Payment from './components/pay-usd.vue'
import Binance from './components/binancepay.vue'
const routes = [
    { path: '/', component: hm },
    { path: '/pay', component: Pay },
    { path: '/pay/:id', component: Pay },
    { path: '/payment', component: Payment },
    { path: '/payment/:id', component: Payment },
    { path: '/binance/:id', component: Binance },
    
]




// 3. Create the router instance and pass the `routes` option
// You can pass in additional options here, but let's
// keep it simple for now.
const router = createRouter({
    // 4. Provide the history implementation to use. We are using the hash history for simplicity here.
    history: createWebHistory(),
    routes, // short for `routes: routes`
})

const app = createApp(App)


// Make sure to _use_ the router instance to make the
// whole app router-aware.
app.use(router)



app.mount('#app')
